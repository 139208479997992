import React from 'react';
import Profile from '../components/profile/profile';
import {Button} from 'primereact/button';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../providers/authProvider";
import {MegaMenu} from 'primereact/megamenu';
import './menu.css';
const Menu = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const items = [
        {
           root: true,
            template: (item, options) => {
                return (
                    <a className="flex align-items-center p-3 cursor-pointer gap-2 shadow-none "
                       onClick={options.onClick}>
                        <span className="font-medium text-lg text-900 text-black md:text-white">Community Votes for Top Universities: 100K Users Earn $QS, Building a Decentralized Network for Educational Fairness!</span>
                    </a>)
            }
        }
    ];

    const start =
        <div className="flex-1">

        </div>;

    const end = <>
        {auth.token && <Profile/>}
        {!auth.token && <>
            <Button label="Login" onClick={() => navigate('login')} text className='shadow-none text-white'/>
            <Button label="Register" onClick={() => navigate('register')} className='shadow-none'/>
        </>}
    </>;


    return (
        <MegaMenu model={items} end={end} orientation="horizontal" breakpoint="960px"
                  className="p-3 surface-0 bg-black text-white"/>
    );
};


export default Menu;