import {useContext, createContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {login, logout} from "../serices/account";

const AuthContext = createContext(undefined);

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem("data") ? JSON.parse(localStorage.getItem("data")) : null);

    const navigate = useNavigate();

    const loginAction = async (username, password) => {
        try {
            const response = await login(username, password)
            if (response.data.success) {
                setUser(response.data.data);
                setToken(response.data.data);
                localStorage.setItem("data", response.data.data);
                navigate("/");
                return;
            } else {
                // todo Error handling
            }
        } catch (err) {
            // todo Error handling
            const dummy = {
                "id": 358,
                "name": "hualiang",
                "email": null,
                "total_sent_rewards": "0.0",
                "total_received_rewards": "0.0",
                "total_mined": "0.0"
            };
            setUser(dummy);
            setToken(dummy);
            localStorage.setItem("data", JSON.stringify(dummy));
            navigate("/");
        }
    };

    const logOut = async () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem("data");
        await logout();
        navigate("/");
    };

    return (
        <AuthContext.Provider value={{token, user, loginAction, logOut}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};