import React, {useState} from "react";
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {useAuth} from "../providers/authProvider";

const RegisterPage = () => {
    const [input, setInput] = useState({
        username: "",
        password: "",
        password_confirm: "",
    });

    const auth = useAuth();

    const handleInput = (e) => {
        const {name, value} = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleSubmitEvent = async (e) => {
        e.preventDefault();
        if (input.username !== "" && input.password !== "") {
            await auth.loginAction(input.username, input.password);
            return;
        }
    };

    return (
        <form onSubmit={handleSubmitEvent} className='flex flex-col gap-4 items-center justify-center pt-10'>
            <div className="p-inputgroup  w-full md:w-[800px]">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-user"></i>
                    </span>
                <InputText name="username" placeholder="Email" className='shadow-none' onChange={handleInput}/>
            </div>
            <div className="p-inputgroup  w-full md:w-[800px]">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-lock"></i>
                    </span>
                <Password name="password" placeholder="Password" className="shadow-none" onChange={handleInput}
                          feedback={false} tabIndex={1}/>
            </div>
            <div className="p-inputgroup  w-full md:w-[800px]">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-lock"></i>
                    </span>
                <Password name="password_confirm" placeholder="Password Confirm" className="shadow-none" onChange={handleInput}
                          feedback={false} tabIndex={1}/>
            </div>
            <Button className="mt-2  w-full md:w-[800px]" label="Register"/>
        </form>
    );

};

export default RegisterPage;