import React from "react";

const MenuSection = ({label, icon, handleClick}) => {
    return (
        <div className="pr-2" onClick={handleClick}>
            <i className={`pi ${icon}`}></i>
            <span className='p-2'>{label}</span>
        </div>
    );
}

export default MenuSection;