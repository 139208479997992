import React from "react";

const MenuSection = ({title, children}) => {
    return (
        <span className="flex flex-col gap-4 py-4 pl-4">
            <div className="font-bold">{title}</div>
            {children}
        </span>
    );
}

export default MenuSection;