import React, {useRef} from 'react';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {Toast} from 'primereact/toast';
import {Avatar} from 'primereact/avatar';
import {useAuth} from "../../providers/authProvider";
import MenuSection from "./menu/menuSection";
import MenuItem from "./menu/menuItem";

const Profile = () => {
    const menu = useRef(null);
    const auth = useAuth();

    let items = [
        {
            template: () => {
                return (
                    <MenuSection title="Points">
                        <MenuItem icon="pi-wallet" label="Balance: "/>
                        <MenuItem icon="pi-wave-pulse" label="Total: "/>
                        <MenuItem icon="pi-check-square" label="Voted: "/>
                    </MenuSection>
                );
            }
        },
        {separator: true},
        {
            template: () => {
                return (
                    <MenuSection title="Referral">
                        <MenuItem icon="pi-verified" label="Referred: "/>
                        <MenuItem icon="pi-share-alt" label="Share: "/>
                    </MenuSection>
                );
            }
        },
        {separator: true},
        {
            template: () => {
                return (
                    <MenuSection title="Profile">
                        <MenuItem icon="pi-sign-out" label="Logout" handleClick={() => auth.logOut()}/>
                    </MenuSection>
                );
            }
        }
    ];

    return (
        <div className='flex justify-center'>
            <Menu model={items} popup ref={menu} id="popup_menu"/>
            <Avatar image="https://ui-avatars.com/api/name=Hua&background=random"
                    onClick={(event) => menu.current.toggle(event)}
                    shape="circle" className='mr-4'/>
        </div>
    );

};

export default Profile;