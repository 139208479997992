import instance from "../configs/axiosConfig";

const login = async (username, password) => {
    console.log('asdfa');
    return instance.post('v1/sessions/login', {
        username,
        password
    });
};

const register = async (username, password) => {
    return instance.post('v1/sessions/sign_up', {
        username,
        password,
        password_confirmation: password
    });
};

const logout = async () => {
    return instance.post('v1/sessions/logout', {});
};

const info = async () => {
    return instance.get('/users/account');
};

// TODO
const logs = async (page, items) => {
    return instance.get('/users/account_logs');
};
export {
    login,
    register,
    logout,
    info,
    logs
}