import React, {useState, useRef, useEffect} from 'react';
import {Button} from 'primereact/button';
import {DataScroller} from 'primereact/datascroller';
import {Dialog} from 'primereact/dialog';
import {IconField} from "primereact/iconfield";
import {InputIcon} from "primereact/inputicon";
import {InputText} from "primereact/inputtext";
import './RankingPage.css';
import Comments from '../components/comments/comments';
import data from './universities.json';
import {getUniversities} from "../serices/university";

const RankingsPage = () => {
    const [rankings, setRankings] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState(null);
    const ds = useRef(null);

    useEffect(() => {

        const getUniversity = async () => {
            try {
                const response = await getUniversities();
                if (response.data?.success) {
                    // setRankings([]);
                    setRankings(response.data.data.posts);
                } else {
                    // todo

                }
            } catch (err) {
                // todo
            }

        }
        getUniversity();


    }, []);

    const openComment = (data) => {
        setVisible(true);
        setSelected(data);
    }

    const template = (data) => {
        return (
            <div className="template">
                <div className="flex flex-wrap items-center p-4 gap-4 w-full text-center md:text-start ">
                    <div className="w-full md:w-1/12 text-center text-2xl">{data.id}</div>
                    <img className="w-full md:w-1/12" src={data.src} alt={data.name}/>
                    <div className="w-full md:w-6/12 flex-col gap-6 md:items-start justify-center">
                        <div className="text-2xl font-bold text-900 text-center md:text-start pb-2">{data.name}</div>
                        <i className='pi pi-map-marker pr-2 text-center md:text-start'></i>{data.address}
                    </div>
                    <div className="w-full md:w-1/12 text-xl"><span>{data.score}</span></div>
                    <div className="w-full md:w-2/12 flex flex-col items-center">
                        <div className="gap-2">
                            <Button className='border-0 shadow-none pt-0 mt-0' icon="pi pi-thumbs-up" text></Button>
                            <Button className='border-0 shadow-none pt-0 mt-0 ' icon="pi pi-thumbs-down" text></Button>
                        </div>
                        <Button className='shadow-none p-2' outlined onClick={() => openComment(data)}>Comments</Button>

                    </div>
                </div>
            </div>
        );
    };


    const footer = <Button type="text" icon="pi pi-plus" label="Load More" className='shadow-none'
                           onClick={() => ds.current.load()}/>;

    const handleChange = (e) => {
        if (e.target.value) {
            setRankings(data.filter(r => r.name.toLowerCase().includes(e.target.value.toLowerCase())));
        } else {
            setRankings(data);
        }
    };

    return (
        <div>
            <div className="flex ml-6 mt-4">
                <IconField iconPosition="left" className="w-full pr-6 md:w-fit">
                    <InputIcon className="pi pi-search"></InputIcon>
                    <InputText placeholder="University Search" onChange={handleChange} className='shadow-none w-full'/>
                </IconField>
            </div>
            <DataScroller ref={ds} value={rankings} itemTemplate={template}
                          rows={5} loader footer={footer}
                          emptyMessage ="No university data"/>
            <div className="card flex justify-content-center">
                <Dialog visible={visible} modal onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
                        style={{width: '50vw'}} breakpoints={{'960px': '75vw', '641px': '100vw'}}>
                    {selected && <Comments currentUserId="1"/>}
                </Dialog>
            </div>
        </div>
    );
};

export default RankingsPage;