import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Layout from './pages/Layout';
import RankingsPage from './pages/RankingsPage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import AuthProvider from "./providers/authProvider";
import React, {useState, useEffect} from 'react';
import {BlockUI} from "primereact/blockui";
import {setLoadingInterceptor} from "./configs/axiosConfig";

function App() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoadingInterceptor(setLoading);
    }, []);

    return (
        <BrowserRouter>
            <BlockUI blocked={loading} fullScreen/>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<RankingsPage/>}/>
                        <Route path="register" element={<RegisterPage/>}/>
                        <Route path="login" element={<LoginPage/>}/>
                    </Route>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
