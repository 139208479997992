import {useState} from "react";
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';

const CommentForm = ({
                         handleSubmit,
                         hasCancelButton = false,
                         handleCancel,
                         initialText = "",
                     }) => {
    const [text, setText] = useState(initialText);
    const isTextareaDisabled = text.length === 0;
    const onSubmit = (event) => {
        event.preventDefault();
        handleSubmit(text);
        setText("");
    };
    return (
        <form onSubmit={onSubmit}>
            <InputText value={text} onChange={(e) => setText(e.target.value)}
                       className="w-full mb-2 shadow-none border-b-1 border-t-0 border-x-0 border-black rounded-none"/>
            <div className="pt-2">
                <Button label="Comment" className="shadow-none mr-4 p-2" disabled={isTextareaDisabled}/>
                {hasCancelButton && (
                    <Button label="Cancel" severity="secondary" className="shadow-none p-2" onClick={handleCancel}/>
                )}
            </div>

        </form>
    );
};

export default CommentForm;
