import axios from 'axios';

const getBaseUrl = () => {
    console.log(process.env.REACT_APP_DEFAULT_SERVICE_ENDPOINT);

    return process.env.REACT_APP_DEFAULT_SERVICE_ENDPOINT;
};

let setLoading;

const instance = axios.create({
    baseURL: getBaseUrl()
});

const setLoadingInterceptor = (status) => {
    setLoading = status;

    instance.interceptors.request.use(
        (config) => {
            if (setLoading) setLoading(true);
            return config;
        },
        (error) => {
            if (setLoading) setLoading(false);
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (response) => {
            if (setLoading) setLoading(false);
            return response;
        },
        (error) => {
            if (setLoading) setLoading(false);
            return Promise.reject(error);
        }
    );
};

export { setLoadingInterceptor };
export default instance;
