import {Button} from "primereact/button";
import CommentForm from "./commentForm";

const Comment = ({
                     comment,
                     replies,
                     setActiveComment,
                     activeComment,
                     updateComment,
                     deleteComment,
                     addComment,
                     parentId = null,
                     currentUserId,
                 }) => {
    const isEditing =
        activeComment &&
        activeComment.id === comment.id &&
        activeComment.type === "editing";
    const isReplying =
        activeComment &&
        activeComment.id === comment.id &&
        activeComment.type === "replying";
    const fiveMinutes = 300000;
    const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;
    const canDelete =
        currentUserId === comment.userId && replies.length === 0 && !timePassed;
    const canReply = Boolean(currentUserId) != comment.userId;
    const canEdit = currentUserId === comment.userId && !timePassed;
    const replyId = parentId ? parentId : comment.id;
    const createdAt = new Date(comment.createdAt).toLocaleDateString();

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    return (
        <div key={comment.id} className="flex gap-4 m-4">
            <div>
                <img className="rounded-full" src="https://ui-avatars.com/api/name=Hua&background=random"/>
            </div>
            <div className="w-full">
                <div className="flex">
                    <div className="font-bold pr-2">{comment.username}</div>
                    <div>{createdAt}</div>
                </div>
                {!isEditing && <div className="mt-2">{comment.body}</div>}
                {isEditing && (
                    <CommentForm
                        submitLabel="Update"
                        hasCancelButton
                        initialText={comment.body}
                        handleSubmit={(text) => updateComment(text, comment.id)}
                        handleCancel={() => {
                            setActiveComment(null);
                        }}
                    />
                )}
                <div className="flex text-sm py-2">
                    {canReply && (
                        <Button className="p-0 m-0 shadow-none"
                                onClick={() => setActiveComment({id: comment.id, type: "replying"})}
                                severity="secondary" text>Reply</Button>
                    )}
                    {canReply && (
                        <div>
                            <Button className="p-0 shadow-none" icon="pi pi-thumbs-up" severity="secondary" outlined
                                    text></Button>
                            <span>{getRandomNumber(0, 200)}</span>
                        </div>
                    )}
                    {canEdit && (
                        <Button className="p-0  mr-2 shadow-none"
                                onClick={() => setActiveComment({id: comment.id, type: "editing"})} severity="secondary"
                                text>Edit</Button>
                    )}
                    {canDelete && (
                        <Button className="p-0  m-0 shadow-none" onClick={() => deleteComment(comment.id)}
                                severity="secondary" text>Delete</Button>
                    )}
                </div>
                {isReplying && (
                    <CommentForm submitLabel="Reply" severity="secondary"
                                 handleSubmit={(text) => addComment(text, replyId)}/>
                )}
                {replies.length > 0 && (
                    <div className="mt-2">
                        {replies.map((reply) => (
                            <Comment
                                comment={reply}
                                key={reply.id}
                                setActiveComment={setActiveComment}
                                activeComment={activeComment}
                                updateComment={updateComment}
                                deleteComment={deleteComment}
                                addComment={addComment}
                                parentId={comment.id}
                                replies={[]}
                                currentUserId={currentUserId}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Comment;
