import { Outlet, Link } from "react-router-dom";
import Menu from "./Menu";

const Layout = () => {

  return (
    <div className="w-full">
      <Menu/>
      <Outlet />
    </div>
  );
};

export default Layout;